exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-car-insurance-js": () => import("./../../../src/pages/en/car-insurance/[...].js" /* webpackChunkName: "component---src-pages-en-car-insurance-js" */),
  "component---src-pages-en-test-js": () => import("./../../../src/pages/en/test.js" /* webpackChunkName: "component---src-pages-en-test-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sycmaster-js": () => import("./../../../src/pages/sycmaster.js" /* webpackChunkName: "component---src-pages-sycmaster-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-blog-detail-js": () => import("./../../../src/templates/blog-detail.js" /* webpackChunkName: "component---src-templates-blog-detail-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-faqs-js": () => import("./../../../src/templates/faqs.js" /* webpackChunkName: "component---src-templates-faqs-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-insurance-dictionary-js": () => import("./../../../src/templates/insurance-dictionary.js" /* webpackChunkName: "component---src-templates-insurance-dictionary-js" */),
  "component---src-templates-makeclaim-js": () => import("./../../../src/templates/makeclaim.js" /* webpackChunkName: "component---src-templates-makeclaim-js" */),
  "component---src-templates-offer-js": () => import("./../../../src/templates/offer.js" /* webpackChunkName: "component---src-templates-offer-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-products-js": () => import("./../../../src/templates/products.js" /* webpackChunkName: "component---src-templates-products-js" */),
  "component---src-templates-search-js": () => import("./../../../src/templates/search.js" /* webpackChunkName: "component---src-templates-search-js" */),
  "component---src-templates-terms-js": () => import("./../../../src/templates/terms.js" /* webpackChunkName: "component---src-templates-terms-js" */)
}

